<template>
  <div class="quiz-page bg-slate">
    <the-navigation></the-navigation>

    <the-popup v-if="popupactive" @close="closepopup">
      <template #title>{{ alert.title }}</template>
      <template #message>{{ alert.message }}</template>
    </the-popup>

    <div class="sticky top-0 z-10 bg-slate">
      <div class="max-w-screen-xl container py-4 m-auto">
        <div class="flex justify-between">
          <div class="remaining-ques">{{ que_num }}/30 completed</div>
          <div class="time font-semibold">{{ formattedTimeLeft }} mins</div>
        </div>

        <div class="progress w-full bg-grey rounded mt-4">
          <div
            class="bg-secondary rounded w-0 h-3 lg:h-6 transition-all"
            id="q1"
          ></div>
        </div>
      </div>
    </div>

    <div class="max-w-screen-xl container py-10 m-auto">
      <div class="instruction my-10">
        <the-instructions class="opacity-80">
          <p class="md:text-base">
            On the following pages are several pairs of statements describing
            possible behavioral responses. For each pair, please select the “A”
            or “B” statement which is most characteristic of your own behavior.
            It is possible that in some cases, neither the “A” nor the “B”
            statement may be very typical of your behaviors, however, you
            requested to select the response which you may be most likely true
            of you.
          </p>
        </the-instructions>
      </div>

      <div class="questions my-24">
        <div class="" v-for="question in quiz" :key="question.id">
          <question-answer
            :id="'question' + question.order"
            :q_id="question.id"
            :answerSet="question.answer_set"
            :label="question.label"
            :order="question.order"
            @values="compileAnswers"
            @answerData="getAnswerData"
            :quizTwoSelections="quizTwoSelections"
          ></question-answer>
        </div>
      </div>

      <div class="flex justify-between">
        <div
          @click="saveAndGoBack"
          class="previous bg-white sm:px-8 sm:py-4 btn text-tetiary"
        >
          Previous
        </div>
        <a @click="submitAnswers" class="btn sm:px-8 sm:py-4"
          >Proceed to section C</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAnswer from "../components/quizes/genericquiz/QuestionAnswer.vue";
import TheNavigation from "../components/TheNavigation.vue";
import TheInstructions from "../components/quizes/TheInstructions.vue";
import quiz from "../utils/database-2.json";
import ThePopup from "../components/ThePopup.vue";
import { mapState } from "vuex";

const TIME_LIMIT = 86400;

export default {
  components: { TheInstructions, QuestionAnswer, TheNavigation, ThePopup },
  name: "QuizPageTwo",
  data() {
    return {
      timerInterval: null,
      timePassed: 0,
      quiz: {},
      answers: {},
      que_num: 0,
      popupactive: false,
      scrollto: null,
      Json: "",
      // users_answers: [],
      choices: {},
      quiz_data: {
        quiz_id: 2,
        quiz_type: "mcq_2",
        quiztaker_id: this.$store.state.user.quizTaker.id,
        users_answers: [],
      },
      alert: {
        title: "hello",
        message: "this is the message",
      },
    };
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        alert("Time Up");
      }
    },

    //progress bar
    answers: {
      handler(newValue) {
        let keys = Object.keys(newValue);

        //increments progress bar
        let elem = document.getElementById("q1");
        this.que_num = keys.length;
        keys.length == 30
          ? (elem.style.width = "100%")
          : (elem.style.width = keys.length * 3.33 + "%");
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("selection", ["quizTwoSelections"]),

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      // hours mintues seconds
      let hours = Math.floor(timeLeft / 3600);
      let minutes = Math.floor((timeLeft - hours * 3600) / 60);
      let seconds = timeLeft - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = `0${hours}`;
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      return this.timeLeft / TIME_LIMIT;
    },
  },

  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed = this.timePassed += 1;
      }, 1000);
    },

    //this function saves users answers if user completed before going back
    saveAndGoBack() {
      if (Object.keys(this.answers).length == 30) {
        this.$store.commit("selection/saveQuizTwoSelection", this.answers);
      }
      this.$router.back();
    },

    closepopup() {
      this.popupactive = false;

      // this scrolls to uncompleted answer
      this.$router.push({ name: "QuizTwo", hash: "#question" + this.scrollto });
    },

    //function to compile answers
    compileAnswers(order, value) {
      if (value == "A") {
        this.answers[`${order}`] = value;
      } else if (value == "B") {
        this.answers[`${order}`] = value;
      }
    },

    getAnswerData(question_id, answer_id, answer) {
      const answer_data = {
        answerId: answer_id,
        choice: answer,
        Question: question_id,
      };
      this.choices[`${question_id}`] = answer_data;
      this.$store.commit("SET_QUIZ_TWO_ANSWERS", this.choices);
      return answer_data;
    },

    sendAnswers() {
      const saved_answers = this.$store.state.quiz.quiz_two_answers;

      // get the keys of the object
      let keys = Object.keys(saved_answers);
      for (let i = 0; i < keys.length; i++) {
        let question_id = saved_answers[keys[i]].Question;
        let answers = [];
        let answer_keys = Object.keys(saved_answers[keys[i]].choice);
        const score = 1;
        const answer = {
          option: saved_answers[keys[i]].choice[answer_keys[0]],
          score: score,
          id: saved_answers[keys[i]].answerId,
        };
        answers.push(answer);

        this.quiz_data.users_answers.push({
          question_id: question_id,
          answers: answers,
        });
      }

      // submit answers to the server
      this.$store.dispatch("submitAnswers", this.quiz_data);
    },

    submitAnswers() {
      let keys = Object.keys(this.answers);
      if (keys.length == 30) {
        this.sendAnswers();
        this.$store.commit("selection/saveQuizTwoSelection", this.answers); //This keeps user's selections in the store
        // this.Json = JSON.stringify(this.answers);
        this.$router.push({ name: "QuizThree" });
        // console.log(this.Json);
      } else {
        this.alert.message = "Answer all questions";
        this.alert.title = "Info";
        this.popupactive = true;
        this.findUnanswered();
      }
    },

    //function to scroll to unanswered
    findUnanswered() {
      let answered = Object.keys(this.answers);
      console.log(answered);

      for (let i = 1; i <= 30; i++) {
        if (!answered.includes(`${i}`)) {
          var num = i;
          break;
        }
      }
      this.scrollto = num;
      console.log(this.scrollto);
    },

    readJsonFile() {
      let quiz_set = quiz;
      this.quiz = quiz_set.quiz.question_set;
      console.log(this.quiz);
    },
  },

  // this function makes user confirm before leaving page
  beforeRouteLeave() {
    if (Object.keys(this.answers).length !== 30) {
      const answer = window.confirm(
        "Do you really want to leave? Changes you made may not be saved."
      );
      if (!answer) return false;
    }
  },

  created() {
    this.startTimer();
    // this function makes sure the user comfirms refreshing page or closing the tab.
    window.addEventListener("beforeunload", function (event) {
      event.returnValue = "Write something clever here..";
    });
  },

  mounted() {
    this.readJsonFile();
  },
};
</script>

<style scoped>
.previous {
  box-shadow: 0px 4px 5px rgba(66, 93, 235, 0.21);
}
.previous:hover {
  box-shadow: 0px 4px 20px rgba(66, 93, 235, 0.21);
}

.previous:active {
  box-shadow: none;
}
</style>